/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from 'react';
import type { AppProps } from 'next/app';
import { DefaultSeo } from 'next-seo';
import { AnimatePresence, motion } from 'framer-motion';

import 'src/styles/next.scss';
// import 'dist/assets/components.css';
import { withRouter } from 'next/router';
import { PageLoading } from 'components/atoms/page-loading';

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import cn from '../../public/locales/cn/translation.json';
import th from '../../public/locales/th/translation.json';
import { BASE_URL } from 'libs/constants';

const i18nOptions: any = {
  whitelist: ['cn', 'th'],
  lng: 'cn',
  fallbackLng: 'cn',
  // debug: true,
  interpolation: {
    escapeValue: false,
  },
  useSuspense: false,
  resources: {
    cn: {
      translation: cn,
    },
    th: {
      translation: th,
    },
  },
};

i18n.use(LanguageDetector).use(initReactI18next).init(i18nOptions);

// export function reportWebVitals(metric: NextWebVitalsMetric) {
/* eslint-disable no-console */
// console.log(metric);
//}

function QSNCCApp({ Component, pageProps, router }: AppProps) {
  // const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);

  const lang = useMemo(() => {
    return router.asPath.startsWith('/th') ? 'th' : 'cn';
  }, [router.asPath]);

  useEffect(() => {
    i18n.changeLanguage(lang);
    const cwcCookieBanner = (window as any).cwcCookieBanner;
    if (cwcCookieBanner) {
      cwcCookieBanner.setLang(lang);
    }
  }, [lang]);

  useEffect(() => {
    document.documentElement.lang = lang;
  }, [lang, router]);

  useEffect(() => {
    const handleRouteChangeStart = (url: string, options: any) => {
      if (url !== router.asPath && !options.shallow) {
        setIsLoading(true);
      }
    };
    const handleRouteChangeComplete = (url: string, options: any) => {
      if (url === router.asPath && !options.shallow) {
        setIsLoading(false);
      }
    };

    router.events.on('routeChangeStart', handleRouteChangeStart);
    router.events.on('routeChangeComplete', handleRouteChangeComplete);
    router.events.on('routeChangeError', handleRouteChangeComplete);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
      router.events.off('routeChangeError', handleRouteChangeComplete);
    };
  }, [router.asPath, router.events]);

  return (
    <div>
      <DefaultSeo
        titleTemplate="QSNCC | %s"
        defaultTitle="泰国诗丽吉王后国家会议中心"
        description="泰国诗丽吉王后国家会议中心"
        additionalMetaTags={[
          {
            name: 'keywords',
            content: '泰国诗丽吉王后国家会议中心',
          },
          // { name: 'author', content: 'Super User' },
          {
            name: 'description',
            content: '泰国诗丽吉王后国家会议中心',
          },
          { name: 'generator', content: 'Designed & Developed by FOURDIGIT (THAILAND).' },
          { name: 'theme-color', content: '#827800' },
        ]}
        additionalLinkTags={[
          { rel: 'manifest', href: '/manifest.json' },
          { rel: 'shortcut icon', href: '/favicon.ico', type: 'image/x-icon' },
          { rel: 'icon', type: 'image/x-icon', href: '/favicon.ico' },
          { rel: 'icon', type: 'image/png', href: '/favicons/32.png', sizes: '32x32' },
          { rel: 'apple-touch-icon', href: '/favicons/60.png' },
          { rel: 'apple-touch-icon', href: '/favicons/76.png', sizes: '76x76' },
          { rel: 'apple-touch-icon', href: '/favicons/120.png', sizes: '120x120' },
          { rel: 'apple-touch-icon', href: '/favicons/152.png', sizes: '152x152' },
          // { rel: 'canonical', href: `${BASE_URL}/${lang}`},
        ]}
        openGraph={{
          type: 'website',
          locale: lang,
          url: `${BASE_URL}/`,
          title: '泰国诗丽吉王后国家会议中心',
          description: '泰国诗丽吉王后国家会议中心',
          images: [
            {
              url: `${BASE_URL}/images/hero-banners/home-hero-1.jpg`,
              width: 2880,
              height: 1800,
              alt: '泰国诗丽吉王后国家会议中心',
            },
            {
              url: `${BASE_URL}/images/hero-banners/home-hero-2.jpg`,
              width: 2880,
              height: 1800,
              alt: '泰国诗丽吉王后国家会议中心',
            },
          ],
          videos: [
            {
              url: `${BASE_URL}/videos/QSNCC_1080p.mp4`,
              type: 'video/mp4',
              width: 1920,
              height: 1080,
            }
          ],
          site_name: '泰国诗丽吉王后国家会议中心',
        }}
        twitter={{
          handle: '@QSNCC',
          site: '@QSNCC',
          cardType: 'summary_large_image',
        }}
      />
      <AnimatePresence>
        {isLoading && (
          <motion.div
            className="p-app__loader"
            key="core-loader"
            initial={{
              opacity: 0,
              // scale: 2,
            }}
            animate={{
              opacity: isLoading ? 1 : 0,
              // scale: isLoading ? 1 : 2,
            }}
            exit={{
              opacity: 0,
              // scale: 2,
            }}
            transition={{
              duration: 0.5,
              // type: 'spring',
            }}
          >
            <PageLoading animated={true} />
          </motion.div>
        )}
        <Component {...pageProps} />
      </AnimatePresence>
    </div>
  );
}
export default withRouter(QSNCCApp);
